// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/context/InfoModalContext.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/context/InfoModalContext.tsx");
  import.meta.hot.lastModified = "1725573846000";
}
// REMIX HMR END

import { createContext, useContext, useReducer } from "react";
const InfoModalContext = createContext(undefined);
export const useInfoModalContext = () => {
  _s();
  const context = useContext(InfoModalContext);
  if (!context) {
    throw new Error('useInfoModalContext must be used within a InfoModalProvider');
  }
  return context;
};
_s(useInfoModalContext, "b9L3QQ+jgeyIrH0NfHrJ8nn7VMU=");
const infoModalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_INFO_TASK':
      return {
        ...state,
        infoTask: action.payload,
        open: true,
        activeTab: 'infoTask'
      };
    case 'SET_INFO_LEAD':
      return {
        ...state,
        infoLead: action.payload,
        open: true,
        activeTab: 'infoLead'
      };
    case 'SET_INFO_SUBUSER':
      return {
        ...state,
        infoSubUser: action.payload,
        open: true,
        activeTab: 'infoSubUser'
      };
    case 'SET_INFO_CLOSURE':
      return {
        ...state,
        infoClosure: action.payload,
        open: true,
        activeTab: 'infoClosure'
      };
    case 'CLEAR':
      return {
        ...state,
        infoTask: {
          tarid: 0,
          usuid: 0
        },
        infoLead: {
          lead: 0
        },
        infoSubUser: 0,
        open: false,
        activeTab: 'infoLead'
      };
    default:
      return state;
  }
};
export const InfoModalProvider = ({
  children
}) => {
  _s2();
  const [state, dispatch] = useReducer(infoModalReducer, {
    open: false,
    activeTab: 'infoLead',
    infoLead: {
      lead: 0
    },
    infoTask: {
      tarid: 0,
      usuid: 0
    },
    infoSubUser: 0,
    infoClosure: {
      cieid: 0
    }
  });
  const handleInfoModal = args => {
    switch (args.tab) {
      case "infoTask":
        dispatch({
          type: 'SET_INFO_TASK',
          payload: args.data
        });
        break;
      case "infoLead":
        dispatch({
          type: 'SET_INFO_LEAD',
          payload: args.data
        });
        break;
      case "infoSubUser":
        dispatch({
          type: 'SET_INFO_SUBUSER',
          payload: args.data
        });
        break;
      case "infoClosure":
        dispatch({
          type: 'SET_INFO_CLOSURE',
          payload: args.data
        });
        break;
    }
  };
  const handleClose = () => {
    dispatch({
      type: 'CLEAR'
    });
  };
  return <InfoModalContext.Provider value={{
    ...state,
    handleInfoModal,
    handleClose
  }}>
            {children}
        </InfoModalContext.Provider>;
};
_s2(InfoModalProvider, "LLY7wZsu2V82niWxgtEy+zzr/10=");
_c = InfoModalProvider;
var _c;
$RefreshReg$(_c, "InfoModalProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;