// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { json } from "@remix-run/node";
import { LiveReload, Outlet, Links, Scripts, useLoaderData, Meta, ScrollRestoration } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next";
import { i18nCookie } from "./cookie";
import { ViewStateProvider } from "./context/ViewContext";
import { FormModalProvider } from "~/context/FormModalContext";
import { InfoModalProvider } from "~/context/InfoModalContext";
import { ThemeProvider, CssBaseline, createTheme } from "@mui/material";
import { useEffect } from "react";
import { esES, enUS, ptBR } from "@mui/material/locale";
import ServerError from "./components/shared/error/ServerError";
import globalStyles from "./styles/global.css";
import theme from "./common/Theme/theme";
import faviconAssetUrl from "./assets/favicon.ico";
import i18next from "~/i18next.server";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/pt";
export async function loader({
  request
}) {
  const locale = await i18next.getLocale(request);
  return json({
    locale
  }, {
    headers: {
      "Set-Cookie": await i18nCookie.serialize(locale)
    }
  });
}
export default function App() {
  _s();
  let {
    locale
  } = useLoaderData();
  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);
  useChangeLanguage(locale);
  return <Document>
      <ViewStateProvider>
        <FormModalProvider>
          <InfoModalProvider>
            <Outlet />
          </InfoModalProvider>
        </FormModalProvider>
      </ViewStateProvider>
    </Document>;
}
_s(App, "p3HXL6Z01O582SHnE8jRGTNINSk=", false, function () {
  return [useLoaderData, useChangeLanguage];
});
_c = App;
function Document({
  children
}) {
  _s2();
  const {
    i18n
  } = useTranslation();
  const muiLocale = i18n.language === "es" ? esES : i18n.language === "en" ? enUS : ptBR;
  const muiTheme = createTheme(theme, muiLocale);
  return <html lang={i18n.resolvedLanguage} dir={i18n.dir()}>
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s2(Document, "iD7vDB/EPQWin5ATG71yacngHuk=", false, function () {
  return [useTranslation];
});
_c2 = Document;
export function ErrorBoundary({
  error
}) {
  return <html lang="es">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body>
        <ServerError error={error} />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_c3 = ErrorBoundary;
export let handle = {
  i18n: "common"
};
export const links = () => {
  return [{
    rel: "icon",
    href: faviconAssetUrl,
    type: "image/x-icon"
  }, {
    rel: "stylesheet",
    href: globalStyles
  }];
};
export const meta = () => {
  return [{
    title: "Bretit"
  }];
};
var _c, _c2, _c3;
$RefreshReg$(_c, "App");
$RefreshReg$(_c2, "Document");
$RefreshReg$(_c3, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;