// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/common/commonStyles.tsx"
);
import.meta.hot.lastModified = "1725573846000";
}
// REMIX HMR END

import { CallTwoTone, EmailTwoTone, GroupsTwoTone, HourglassBottomTwoTone, MessageTwoTone, PriceChangeTwoTone, VideocamTwoTone } from "@mui/icons-material";
import {
  Tooltip, type TooltipProps,
  tooltipClasses
} from "@mui/material";
import { styled } from "@mui/styles";

// Common Styles

export const bodyStack = {
  minHeight: "100vh",
  background: "linear-gradient(to right, #e7ebef, #fff)",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  mx: "auto",
};

export const mainContainer = {
  mx: "auto",
  width: "100%",
  position: "relative",
};

export const dialogStyle = {
  width: 650,
  maxHeight: "85vh",
  mt: 12,
  px: 2,
  background: "linear-gradient(to right, #f1f1f1, #fff)",
  borderRadius: '11px',
  "&::-webkit-scrollbar": {
    display: 'none'
  }
}

export const modalStyle = {
  position: 'absolute',
  top: 100,
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 650,
  maxHeight: "85vh",
  overflowY: "auto",
  px: 2,
  borderRadius: '11px',
  bgcolor: 'secondary.light',
  transition: 'all 0.5s ease-in-out',
  zIndex: 1000,
  "&::-webkit-scrollbar": {
    display: 'none'
  }
};

export const modalContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "500px",
  overflowY: "auto ",
  borderRadius: "11px",
  backgroundColor: "#fff",
  maxHeight: "85vh",
};

export const blueBoxTitle = {
  backgroundColor: "#0073a4",
  color: "#fff",
  borderRadius: "11px",
  p: 0.75,
};

export const titleTypography = {
  py: 0.25,
  px: 1.25,
};

export const borderBoxShadow = {
  borderRadius: "11px",
  boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
};

export const cardShadow = {
  borderRadius: "11px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.14), 0 2px 4px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 10px 12px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
  }
}

export const softShadow = {
  borderRadius: "11px",
  boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px"
}

export const grayBox = {
  bgcolor: "secondary.dark",
  color: "#0073a4",
};

export const blueButton = {
  ...softShadow,
  color: "#fff",
  bgcolor: "primary.main",
  textTransform: "capitalize",
  "&:hover": {
    bgcolor: "primary.light",
    boxShadow: "rgba(0, 0, 0, 0.21) 0px 3px 6px, rgba(0, 0, 0, 0.28) 0px 3px 6px",
  },
};

export const whiteButton = {
  ...softShadow,
  color: "primary.main",
  bgcolor: "secondary.light",
  textTransform: "capitalize",
  "&:hover": {
    bgcolor: "secondary.main",
    boxShadow: "rgba(0, 0, 0, 0.21) 0px 3px 6px, rgba(0, 0, 0, 0.28) 0px 3px 6px",
  },
};

export const confirmModal = {
  minWidth: "250px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: "auto",
  borderRadius: 4,
  backgroundColor: "#fff",
  color: "#0c6690",
};

export const taskFormAddButton = {
  backgroundColor: "#0c75a6",
  color: "#f1f1f1",
  "&:hover": {
    backgroundColor: "#0c6690",
  },
};

export const paperStyles = {
  ...borderBoxShadow,
  my: 0,
  mx: "auto",
  width: "420px",
  bgcolor: "secondary.main",
  p: 2,
  pt: 6
}

export const styledTColumn = {
  flex: 1,
  justifyContent: "center",
  py: 1,
  borderLeft: "2px solid #8fc2d7",
  alignItems: "center",
};

export const styledTRow = {
  position: "relative",
  transition: "all 0.3s ease",
  "::before": {
    content: "''",
    display: "block",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    borderTop: "2px solid transparent",
    borderBottom: "2px solid transparent",
    zIndex: 1,
  },
  ":hover::before": {
    borderTop: "2px solid #8fc2d7",
    borderBottom: "2px solid #8fc2d7",
  },
};


// Components

export const RedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((theme) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ff1744",
    color: "#fff",
    border: "none",
    borderRadius: "11px",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ff1744",
  },
}));

export const TransparentTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((theme) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "trasparent !important",
    color: "#fff",
    border: "none",
    borderRadius: "11px",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "trasparent",
  },
}));




// Common Functions

export function stringAvatar(name: string) {
  return {
    sx: {
      width: 32,
      height: 32,
      bgcolor: "warning.light",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export function getIconComponent(taskType: number) {
  switch (taskType) {
    case 1:
      return <CallTwoTone />;
    case 2:
      return <GroupsTwoTone />;
    case 3:
      return <VideocamTwoTone />;
    case 4:
      return <PriceChangeTwoTone />;
    case 5:
      return <EmailTwoTone />;
    case 6:
      return <MessageTwoTone />;
    case 7:
      return <HourglassBottomTwoTone />;
    default:
      return null;
  }
}